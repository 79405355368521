import React from "react";
import { Link } from "react-router-dom";

import PhoneImage from "../../../assets/images/phone-image.png";
import PhoneSm from "../../../assets/images/phone-sm.png";
import FirstCentral from "../../../assets/images/firstcentral.svg";
import IdentityPass from "../../../assets/images/identity.svg";
import Octamile from "../../../assets/images/octamile.svg";
import mizala from "../../../assets/images/mizala.svg";
import ProvidusLogo from "../../../assets/images/providus.svg";
import ArmLogo from "../../../assets/images/arm.svg";
import Barcode3 from "../../../assets/images/FrontLogo1.png";
import appstore from "../../../assets/images/appstore.png";
import googlePlay from "../../../assets/images/googlePlay.png";

const TrustedBy = () => {
  return (
    <div className="trusted-by-container">
      <div className="phone-info-box">
        <div className="phone-info-box-text-area">
          <img src={PhoneSm} className="phone-sm" alt="phone" />
          <h1 className="phone-info-box-header">
            Join us in providing Financial<br></br> services to the Unbanked
            <br></br>Africans.
          </h1>

          <div className="subtext-area">
            <p className="subtext">Available for Android and IOS</p>
          </div>
          <div className="download_links">
            <Link to="https://play.google.com/store/apps/details?id=com.regxta.app">
              <img
                src={googlePlay}
                alt="playstore linke"
                className="download_icons"
              />
            </Link>
            <Link to="https://apps.apple.com/us/app/regxta-business/id6467222864">
              <img
                src={appstore}
                alt="app store link"
                className="download_icons"
              />
            </Link>
          </div>
        </div>
        <img src={PhoneImage} className="phone" alt="phone" />
      </div>
      <div className="trusted-by">
        <h1 className="trusted-by-header">Trusted by</h1>
        <div className="logo-row">
          <img src={ProvidusLogo} className="logo" alt="providus" />
          <img src={ArmLogo} className="logo" alt="arm-logo" />
          <img src={IdentityPass} className="logo" alt="identity-pass" />
          <img src={Octamile} className="logo" alt="octamile" />
          <img src={FirstCentral} className="logo" alt="first-central" />
          <img src={mizala} className="logo" alt="mizala" />
        </div>
      </div>
    </div>
  );
};
export default TrustedBy;
