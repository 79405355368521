import React from "react";
import { BriefCase, GlobeIcon, POS, NairaArrow } from "../../../assets/SvgPack";

const AgentBenefits = () => {
  return (
    <div id="features" className="containers">
      <h1 className="header">Agent network benefits</h1>
      <p className="sub-header">
        Get commissions and rewards when you register to become an agent
      </p>
      <div className="box-container flex-center">
        <div className="flex-center">
          <div className="box">
            <div className="icon-container">
              <NairaArrow />
            </div>
            <div>
              <p className="title title-spacing">
                Immediate Commission on Loans
              </p>
              <p className="sub-title">
                You will earn a 2% commission on every loan processed through
                your account (we have paid out over $200,000 this year).
              </p>
            </div>
          </div>

          <div className="box">
            <div className="icon-container">
              <BriefCase />
            </div>
            <div>
              <p className="title title-spacing">
                Bonus on Business Incorporation
              </p>
              <p className="sub-title">
                You will also enjoy a ₦100 bonus on every account registered and
                ₦2,000 for incorporating the account with the CAC.
              </p>
            </div>
          </div>
          {/* </div>
        <div className="flex-center"> */}
          <div className="box">
            <div className="icon-container">
              <POS />
            </div>
            <div>
              <p className="title title-spacing">Get POS Terminals on Loan</p>
              <p className="sub-title">
                You can be a bank on your own by accessing our POS terminal on
                loan and working capital.
              </p>
            </div>
          </div>

          <div className="box">
            <div className="icon-container">
              <GlobeIcon />
            </div>
            <div>
              <p className="title title-spacing">
                Become part of a global effort
              </p>
              <p className="sub-title">
                Join us as we support the United Nations in promoting financial
                inclusion in Africa.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AgentBenefits;
