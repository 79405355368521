import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination } from "swiper";
import { useRef, useState } from "react";
import { Autoplay } from "swiper";
import "./FinancialInitiation.scss";
import goals from "../../../assets/images/goals.png";
import community from "../../../assets/images/community.png";
import { GreenTick } from "../../../assets/SvgPack";
import woman from "../../../assets/images/happy-market-woman.png";
import "swiper/css/autoplay";
import "swiper/css";
import "swiper/css/pagination";

const FinancialInitiation = () => {
  const sliderRef = useRef();
  const containerRef = useRef();

  return (
    <div className="content2">
      <div className="header_wrapper">
        <h1 className="why-we-different-header">Why We're Different</h1>
        <p
          className="why-we-different-par-one-right-text"
          style={{ marginTop: "1rem" }}
        >
          <span>+10,000</span> merchants from diverse geographical locations
          place their trust in us.
        </p>
      </div>

      {/* @ts-ignore */}
      <div className="carousel_wrapper" ref={containerRef}>
        <Swiper
          //  @ts-ignore
          ref={sliderRef}
          direction={"horizontal"}
          loop={true}
          pagination={{
            clickable: true,
          }}
          autoplay={{
            delay: 3500,
            disableOnInteraction: true,
          }}
          modules={[Pagination, Autoplay]}
          className="mySwiper"
        >
          <SwiperSlide>
            <div className="why-we-different-container">
              <div className="why-we-different-par-one-right">
                <img
                  src={woman}
                  className="why-we-different-image"
                  alt="happy-market-woman"
                />
              </div>
              <div className="why-we-different-text-area">
                <h1 className="why-we-different-header">
                  Building a Financial System for Microbusinesses
                </h1>
                <p className="why-we-different-text">
                  We help microbusinesses:
                </p>
                <p className="why-we-different-text">
                  <span className="tick-icon">
                    <GreenTick />
                  </span>{" "}
                  Access online banking services
                </p>
                <p className="why-we-different-text">
                  <span className="tick-icon">
                    <GreenTick />
                  </span>{" "}
                  Simplify payments
                </p>
                <p className="why-we-different-text">
                  <span className="tick-icon">
                    <GreenTick />
                  </span>{" "}
                  Get business credit in 3 hours
                </p>
                <p className="why-we-different-text">
                  <span className="tick-icon">
                    <GreenTick />
                  </span>{" "}
                  Determine their weekly earnings via our financial record
                  generator
                </p>
              </div>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className="why-we-different-container">
              <div className="why-we-different-par-one-right">
                <img
                  src={goals}
                  className="why-we-different-image"
                  alt="goals"
                />
              </div>

              <div className="why-we-different-text-area">
                <h1 className="why-we-different-header">
                  Aligned with Sustainable <br />
                  Development Goals
                </h1>
                <p className="why-we-different-text">
                  We align our products to help solve the following SDGs:
                </p>
                <p className="why-we-different-text">
                  <span className="tick-icon">
                    <GreenTick />
                  </span>{" "}
                  SDG 1: No Poverty
                </p>
                <p className="why-we-different-text">
                  <span className="tick-icon">
                    <GreenTick />
                  </span>{" "}
                  SDG 2: Zero Hunger
                </p>
                <p className="why-we-different-text">
                  <span className="tick-icon">
                    <GreenTick />
                  </span>{" "}
                  SDG 3: Good Health and Well-Being
                </p>
                <p className="why-we-different-text">
                  <span className="tick-icon">
                    <GreenTick />
                  </span>{" "}
                  SDG 4: Quality Education
                </p>
                <p className="why-we-different-text">
                  <span className="tick-icon">
                    <GreenTick />
                  </span>{" "}
                  SDG 8: Decent Work and Economic Growth
                </p>
              </div>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className="why-we-different-container">
              <div className="why-we-different-par-one-right">
                <img
                  src={community}
                  className="why-we-different-image"
                  alt="goals"
                />
              </div>

              <div className="why-we-different-text-area">
                <h1 className="why-we-different-header">Community Focused</h1>
                <p className="why-we-different-text">
                  We train agents as ambassadors to their rural and semi-urban
                  communities. Our agents help micro business owners:
                </p>
                <p className="why-we-different-text">
                  <span className="tick-icon">
                    <GreenTick />
                  </span>{" "}
                  Open accounts
                </p>
                <p className="why-we-different-text">
                  <span className="tick-icon">
                    <GreenTick />
                  </span>{" "}
                  Register their businesses
                </p>
                <p className="why-we-different-text">
                  <span className="tick-icon">
                    <GreenTick />
                  </span>{" "}
                  Settle their bills and any other expenses
                </p>
              </div>
            </div>
          </SwiperSlide>
        </Swiper>
      </div>
    </div>
  );
};

export default FinancialInitiation;
