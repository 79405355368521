import pics from "../../../assets/images/standing-image.png";
import confetti from "../../../assets/images/confetti.png";

const BecomeAnAgent = () => {
  return (
    <div id="become-an-agent" className="become-an-agent">
      <h1 className="header">Become an Agent in 3 steps</h1>
      <div className="become-an-agent-container">
        <div className="image_container">
          <img src={pics} className="image_container-img" alt="standing-lady" />
        </div>
        <div className="para-one-right">
          <div className="accordion-container">
            <div className="accordion-box">
              <h1 className="accordion-box-number">1</h1>
              <div className="accordion-text-area">
                <h2 className="accordion-header">Download our mobile app</h2>
                <p className="accordion-text">
                  Download the app from Google Playstore or Apple App Store
                </p>
              </div>
            </div>
            <div className="accordion-black-bottom-left"></div>
            <div className="accordion-box">
              <h1 className="accordion-box-number">2</h1>
              <div className="accordion-text-area">
                <h2 className="accordion-header">Sign Up</h2>
                <p className="accordion-text">
                  Follow the instructions provided and enter the required
                  information accurately.
                </p>
              </div>
            </div>
            <div className="right_box">
            <div className="accordion-black-bottom-right"></div>
            </div>

            <div className="accordion-box">
              <h1 className="accordion-box-number">3</h1>
              <div className="accordion-text-area">
                <h2 className="accordion-header">Complete the KYC process</h2>
                <p className="accordion-text">
                  This typically involves verifying your identity and collecting
                  relevant information. This ensures the integrity of the
                  financial services being provided to unbanked individuals.
                </p>
              </div>
            </div>
            <div className="accordion-black-bottom-left"></div>

            <div className="accordion-box">
              <div className="accordion-text-area">
                <h2 className="accordion-header">All Set</h2>
                <p className="accordion-text">
                  Congratulations! Now you can start onboarding customers and
                  provide them with the digital financial solutions your company
                  offers.
                </p>
              </div>
            </div>
            <div className="right_box">
            <div className="accordion-black-bottom-right"></div>
            </div>
          </div>
          {/* <div className="about-us-information">
            <h3 className="about-us-header">
              Get paid for helping small businesses obtain micro loans
            </h3>

            <p className="about_us-sub-header">
              Earn a Commission on loans paid out to small businesses in your
              community.
            </p>
            <div className="button">
              <p>Become a Regxta Agent</p>
            </div>
          </div> */}
        </div>
      </div>
    </div>
  );
};

export default BecomeAnAgent;
