import React, { useState, useEffect } from "react";

const Typewriter = () => {
  const [text, setText] = useState("");
  const [isDeleting, setIsDeleting] = useState(false);
  const [loopNum, setLoopNum] = useState(0);
  const [typingSpeed, setTypingSpeed] = useState(150);

  const words = [
    "Communities",
    "Artisans",
    "Smallholder Farmers",
    "Petty Traders",
    "Street-Hawkers",
    "Low-Cost Schools",
    "Refugees and Immigrants",
  ];
  const colors = [
    "#E1467E",
    "#7A24D0",
    "#8CCA09",
    "#0990CA",
    "#CA0994",
    "CA3709",
    "#04A1AB",
  ]; // Add your desired colors here

  useEffect(() => {
    let timer: any;

    const handleType = () => {
      const current = loopNum % words.length;
      const fullText = words[current];

      setText((prevText) =>
        isDeleting
          ? fullText.substring(0, prevText.length - 1)
          : fullText.substring(0, prevText.length + 1)
      );

      if (!isDeleting && text === fullText) {
        timer = setTimeout(() => setIsDeleting(true), 1500);
      } else if (isDeleting && text === "") {
        setIsDeleting(false);
        setLoopNum(loopNum + 1);
      }

      setTypingSpeed(isDeleting ? 100 : 150); // Adjust speed for deleting and typing
    };

    timer = setTimeout(handleType, typingSpeed);

    return () => clearTimeout(timer);
  }, [text, isDeleting, loopNum, typingSpeed, words]);

  const currentColor = colors[loopNum % colors.length]; // Get the color based on the loopNum

  return (
    <span className="typewriter" style={{ color: currentColor }}>
      {text}
      <span className="cursor">|</span>
    </span>
  );
};

export default Typewriter;
