import React, { useState } from "react";
import { HiPlusSm, HiMinusSm } from "react-icons/hi";

const faqQuestion = [
  {
    id: 1,
    heading: "What is credit?",
    subHeading:
      "The ability of a customer to obtain goods or services before payment, based on the trust that payment will be made in the future.",
    isActive: false,
  },
  {
    id: 2,
    heading: "What do I need to become an agent?",
    subHeading:
      "a. You must have a smart phone \n" +
      "b. You must know how to read and write and able to operate a smart phone.\n" +
      "c. You must have BVN.\n" +
      "d. You must have a regulatory identification card (NIN, Voter's Card, Driver's License, International Passport).\n" +
      "e. You must have an active phone number and bank account.\n" +
      "f. You must be residing or working within a community within our catchment areas.\n" +
      "g. You must possess integrity.\n",
    isActive: false,
  },
  {
    id: 3,
    heading: "Am I working full time or part time as an agent?",
    subHeading: "The job role is on part time.",
    isActive: false,
  },
  {
    id: 4,
    heading: "Do I have transaction limits as an agent?",
    subHeading:
      "Yes. There are 3 levels of agent and each have their limits. Your performance determines your progression.",
    isActive: false,
  },
];

const Accordion = () => {
  const [faq, setFaq] = useState(faqQuestion);

  const handleChange = (id: number) => {
    let data = [...faq];
    const findDataIndex = faq.findIndex((i) => i.id === id);
    data[findDataIndex].isActive = !data[findDataIndex].isActive;
    setFaq(data);
  };

  const renderSubHeading = (items: string | string[]) => {
    if (Array.isArray(items)) {
      return (
        <>
          {items.map((item, idx) => (
            <p className="sub-header" key={idx}>
              {item.split("\n").map((paragraph, idx) => (
                <React.Fragment key={idx}>
                  {paragraph}
                  <br />
                </React.Fragment>
              ))}
            </p>
          ))}
        </>
      );
    }
    return (
      <p className="sub-header">
        {items.split("\n").map((paragraph, idx) => (
          <React.Fragment key={idx}>
            {paragraph}
            <br />
          </React.Fragment>
        ))}
      </p>
    );
  };

  return (
    <div id="faq" className="accordion">
      <div className="accordion-container">
        <h1 className="faq">
          Some of the things you may want to know
        </h1>
        {faq.map((item) => (
          <div key={item.id}>
            <div
              className="accordion-box flex"
              onClick={() => handleChange(item.id)}
            >
              <p className="header mr-5">{item.heading}</p>
              <p className="header">
                {item.isActive ? (
                  <HiMinusSm className="vertical-align" />
                ) : (
                  <HiPlusSm className="vertical-align" />
                )}
              </p>
            </div>
            {item.isActive && renderSubHeading(item.subHeading)}
          </div>
        ))}
        <h2 className="footer">
          Need additional support?{" "}
          <a href="mailto:info@regxta.com">
            <span className="orange-text">Contact us</span>
          </a>
        </h2>
      </div>
    </div>
  );
};

export default Accordion;
