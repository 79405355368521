import React from "react";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import ReactDOM from "react-dom";
import "./styles/sass/index.scss";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import AboutUs from "./components/AboutUs/AboutUs";
import Technology from "./components/Technology";
import Blog from "./Pages/Blog/Blog";
import Services from "./components/Services";
import BusinessSupport from "./components/BusinessSupport";
import DigitalMonitoring from "./components/DigitalMonitoring";
import DigitalWallets from "./components/DigitalWallets";
import OtherFinancialServices from "./components/OtherFinancialServices";
import ServicesAndLoans from "./components/ServicesAndLoans";

const router = createBrowserRouter([
  {
    path: "/",
    element: <App />,
  },
  {
    path: "about-us",
    element: <AboutUs />,
  },
  {
    path: "technology",
    element: <Technology />,
  },
  {
    path: "services",
    element: <Services />,
  },
  {
    path: "blog",
    element: <Blog />,
  },
  {
    path: "digital-wallets",
    element: <DigitalWallets />,
  },
  {
    path: "savings-and-loans",
    element: <ServicesAndLoans />,
  },
  {
    path: "business-support",
    element: <BusinessSupport />,
  },
  {
    path: "digital-monitoring",
    element: <DigitalMonitoring />,
  },
  {
    path: "other-financial-services",
    element: <OtherFinancialServices />,
  },
]);

ReactDOM.render(
  <React.StrictMode>
    <RouterProvider router={router} />
    {/* <App /> */}
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
