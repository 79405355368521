import React from "react";
import { GreenTick } from "../../../assets/SvgPack";
import "./WhyWeDifferent.scss";
import Woman from "../../../assets/images/hero1.svg";

function WhyWeDifferent() {
  return (
    <div className="content1">
      <div className="header_wrapper"></div>
      <div className="why-we-different-container diff-fin">
        <div className="why-we-different-text-area">
          <h1 className="why-we-different-par-one-right-header ">
            The Initiation of Financial Inclusion <br /> Begins Here
          </h1>
          <p className="why-we-different-par-one-right-text">
            We’re helping unbanked and underserved individuals and
            microbusinesses access online banking services, business loans, and
            business support services.
          </p>
          <h1 className="why-we-different-header">
            A Monetary Structure <br /> Beneficial to All Individuals
          </h1>
          <p className="why-we-different-text">
            Our product structure and solutions help to:
          </p>
          <p className="why-we-different-text">
            <span className="tick-icon">
              <GreenTick />
            </span>{" "}
            Speed up business transaction processes
          </p>
          <p className="why-we-different-text">
            <span className="tick-icon">
              <GreenTick />
            </span>{" "}
            Lower business risks
          </p>
          <p className="why-we-different-text">
            <span className="tick-icon">
              <GreenTick />
            </span>{" "}
            Encourage savings and investment
          </p>
          <p className="why-we-different-text">
            <span className="tick-icon">
              <GreenTick />
            </span>{" "}
            Reduce transaction and information costs
          </p>
        </div>
        <div className="why-we-different-par-one-right">
          <img
            src={Woman}
            className="why-we-different-image"
            alt="happy-market-woman"
          />
        </div>
      </div>
    </div>
  );
}

export default WhyWeDifferent;
